import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

const AutocompleteCountries = ({ name, placeholder, selectedCountry, onChange }) => {
    const countries = Utils.getCountriesList()
        .map((country) => ({
            value: country.code,
            label: i18n.t(`countries:country-${country.code.toLowerCase()}`)
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1));

    return (
        <Select
            name={name}
            options={countries}
            value={selectedCountry}
            onChange={onChange}
            components={{ Input: components.Input }}
            isSearchable={true}
            placeholder={placeholder}
            className="card-country-select-collection"
            classNamePrefix="card-country-select-collection"
        />
    );
};

AutocompleteCountries.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    selectedCountry: PropTypes.object,
    onChange: PropTypes.func.isRequired
};

export default AutocompleteCountries;
