// Eternals
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

// Helpers
import Utils from '../../utils/utils';
import i18n from '../../i18n';

// Components
import NewNextArrow from './NewNextArrow';
import NewPrevArrow from './NewPrevArrow';

const CarouselCollection = ({ clickHandler }) => {
    const settings = {
        speed: 500,
        slidesToShow: 3,
        centerPadding: '100px',
        infinite: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Slider {...settings} prevArrow={<NewPrevArrow />} nextArrow={<NewNextArrow />}>
            {_.range(1, 13).map((id) => (
                <div key={id} className="home-card-item" onClick={clickHandler}>
                    <img
                        src={`${Utils.getCloudfrontDomain()}/images/website/collection/home-carousel/home_carousel_${id}.jpg`}
                    />
                    <h3 className="home-title">{i18n.t(`collection:collection-home-title-${id}`)}</h3>
                    <div className="location-home">
                        <i className="icon-ic-location"></i>
                        <p>{i18n.t(`collection:collection-home-location-${id}`)}</p>
                    </div>
                </div>
            ))}
        </Slider>
    );
};

CarouselCollection.propTypes = {
    clickHandler: PropTypes.func
};

export default CarouselCollection;
