import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from '../store';

import LandingPage from '../components/collection/LandingPage';

const collectionPage = document.getElementById('collection-landing-page');
if (collectionPage) {
    createRoot(collectionPage).render(
        <Provider store={store}>
            <LandingPage />
        </Provider>
    );
}
