import React from 'react';
import Utils from '../../../utils/utils';
import i18n from '../../../i18n';

const PressSection = () => (
    <div className="featured-container-background">
        <h1 className="title">{i18n.t('collection:press.title')}</h1>
        <div className="press-container">
            <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/bazaar.svg`} />
            <img
                src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/conde-nast-traveler.svg`}
            />
            <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/haute_living.svg`} />
            <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/robb.svg`} />
            <img
                src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/travel-and-leisure.svg`}
            />
            <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/press/vogue.svg`} />
        </div>
    </div>
);

export default PressSection;
