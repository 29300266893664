// Externals
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Helpers
import i18n from '../../i18n';
import Icons from '../../utils/Icons';
import Utils from '../../utils/utils';

// Components
import TelInput from '../../../src/components/widget/TelInput';

// Services
import Api from '../../api/Api';
import AutocompleteCountries from '../widget/AutocompleteCountries';
import Analytics from '../../utils/analytics';

const LeadGenerationPopup = () => {
    const { register, handleSubmit, errors, control, formState, setValue } = useForm();

    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        register('country', { required: true });
    }, [register]);

    const onSubmit = (data) => {
        Api.LeadsApi.addLeads({
            first_name: data.firstName,
            zip_code: data.zipCode,
            country: data.country,
            email: data.email,
            phone_number: data.phoneNumber,
            product_type: 8,
            target: 1,
            is_already_member: data.isMember,
            marketing_prospecting_accepted: data.marketing_prospecting_accepted
        });
        sweetAlert.close();

        const eventData = {
            collection: true,
            area: 'homepage_collection'
        };

        const userData = {
            language: Utils.getWebsiteLanguage().toUpperCase()
        };

        Analytics.trackGTM('submit_lead_form_collection', {
            event_data: eventData,
            user_data: userData
        });
        window.open(i18n.t('collection:collection-book-url'), '_blank').focus();
    };

    // TODO: #crado remove later when the conflicts between modal lib and the telinput lib are resolved
    // it seems to force the update of the form state
    if (formState.isValid) {
        // eslint-disable-next-line no-unused-vars
        const tempFix = '🙀';
    }

    return (
        <div className="collection-popup">
            <div className="collection-popup-header">
                <div
                    className="icon-collection-close"
                    onClick={() => {
                        sweetAlert.close();
                    }}
                >
                    {Icons.transform(2)}
                </div>
            </div>
            <div className="collection-popup-content">
                <h4 className="collection-popup-title-lead">{i18n.t('collection:leads.popin-title')}</h4>
            </div>
            <div className="popup-with-form">
                <img
                    src={`${Utils.getCloudfrontDomain()}/images/website/collection/collection_luxury_book.svg`}
                />
                <form className="collection-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="collection-form-row">
                        <label>{i18n.t('collection:first_name')}</label>
                        <input
                            className={errors?.firstName && 'input-danger'}
                            name="firstName"
                            placeholder={i18n.t('collection:first_name_placeholder')}
                            type="text"
                            ref={register({ required: true })}
                        />
                        {errors?.firstName?.type === 'required' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.firstname-error')}</span>
                            </div>
                        )}
                    </div>
                    <div className="collection-form-row">
                        <label>{i18n.t('collection:leads.zip-code')}</label>
                        <input
                            className={errors?.zipCode && 'input-danger'}
                            name="zipCode"
                            placeholder={i18n.t('collection:leads.zip-code')}
                            type="text"
                            ref={register({ required: true })}
                        />
                        {errors?.zipCode?.type === 'required' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.zip-code-error')}</span>
                            </div>
                        )}
                    </div>
                    <div className="collection-form-row">
                        <label>{i18n.t('collection:leads.country')}</label>
                        <AutocompleteCountries
                            name="country"
                            selectedCountry={selectedCountry}
                            onChange={(newValue) => {
                                setValue('country', newValue.label, { shouldValidate: true });
                                setSelectedCountry({ value: newValue.value, label: newValue.label });
                            }}
                            placeholder={i18n.t('collection:leads.country-placeholder')}
                        />
                        {errors?.country?.type === 'required' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.country-error')}</span>
                            </div>
                        )}
                    </div>
                    <div className="collection-form-row">
                        <label>{i18n.t('collection:email_address')}</label>
                        <input
                            className={errors?.email && 'input-danger'}
                            name="email"
                            placeholder={i18n.t('collection:email_placeholder')}
                            type="text"
                            ref={register({
                                required: true,
                                pattern: {
                                    value: /\S+@\S+\.\S+/
                                }
                            })}
                        />
                        {errors?.email?.type === 'required' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.email-missing')}</span>
                            </div>
                        )}
                        {errors?.email?.type === 'pattern' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.email-not-valid')}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`collection-form-row collection-phone-input ${
                            errors?.phone && 'input-danger-phone'
                        }`}
                    >
                        <label>{i18n.t('collection:phone_number')}</label>
                        <Controller
                            as={
                                <TelInput
                                    hasErrors={!_.isEmpty(errors.phoneNumber)}
                                    signupCollection={true}
                                />
                            }
                            name="phoneNumber"
                            id="phoneNumber"
                            rules={{ required: true }}
                            control={control}
                        />
                        {errors?.phoneNumber?.type === 'required' && (
                            <div className="text-danger">
                                <i className="icon-alert_red" />
                                <span>{i18n.t('collection:leads.phone-required-error')}</span>
                            </div>
                        )}
                    </div>
                    <div className="collection-form-row">
                        <label className="checkbox-inline checkbox-collection-form">
                            <input
                                className="test"
                                name="isMember"
                                id="isMember"
                                type="checkbox"
                                ref={register}
                            />
                            <span> {i18n.t('collection:leads.already-member')}</span>
                        </label>
                    </div>
                    <div className="collection-form-row">
                        <label className="checkbox-inline checkbox-collection-form">
                            <input
                                name="marketing_prospecting_accepted"
                                id="marketing_prospecting_accepted"
                                type="checkbox"
                                ref={register}
                            />
                            <span> {i18n.t('collection:leads.marketing_prospecting_accepted')}</span>
                        </label>
                    </div>
                    <button
                        className="btn btn-primary btn-collection-black button-collection-form"
                        type="submit"
                    >
                        {i18n.t('collection:leads.guide-button')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LeadGenerationPopup;
