// Externals
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Actions
import { redirectToSearch } from '../../actions/collection';

// Contants
import { currencies } from '../currency';

// Helpers
import Api from '../../api/Api';
import Utils from '../../utils/utils';
import i18n from '../../i18n';
import Screen from '../../utils/Screen';

// Components
import Accordion from '../widget/Accordion';
import CarouselCollection from '../common/CarouselCollection';
import CarouselHeader from '../common/CarouselHeader';
import LeadGenerationPopup from '../popup/LeadGenerationPopup';
import PressSection from './sections/press';

export const LandingPage = (props) => {
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [productPrice, setProductPrice] = useState('');

    const handleProposeMyHome = useCallback(() => {
        document.location.assign(
            `${props.accessToken ? '/collection/subscription/payment' : 'collection/propose-my-home'}`
        );
    }, [props.accessToken]);

    useEffect(() => {
        Api.Product.getPriceCollection(props.localeDescription).then((price) => {
            setProductPrice(price.price);
            if (currencies[price.currency]) {
                setCurrencySymbol(currencies[price.currency]);
            } else {
                setCurrencySymbol('€');
            }
        });
    }, []);

    const handleModal = useCallback(() => {
        sweetAlert({
            content: <LeadGenerationPopup />,
            buttons: false,
            closeOnClickOutside: true,
            className: 'collection-popup-modal-large'
        });
    }, []);

    const readMore = (e, index) => {
        e.preventDefault();
        const btnText = document.getElementsByClassName('btn-readMore')[index - 1];
        const text = document.getElementsByClassName('concept-block-text')[index - 1];

        if (text && btnText) {
            if (text.style.height === '90px') {
                text.style.height = '100%';
                btnText.innerHTML = 'Read less';
            } else {
                text.style.height = '90px';
                btnText.innerHTML = 'Read more';
            }
        }
    };

    return (
        <>
            {props.accessToken && (
                <a className="back-button" href={i18n.t('collection:regular-url')}>
                    <i className="icon-left_arrow"></i>
                    <img src={`${Utils.getCloudfrontDomain()}/images/website/favicon/favicon.ico`} alt="" />
                    <span>{i18n.t('collection:title-back-button')}</span>
                </a>
            )}
            <div className="carousel-header-container">
                <CarouselHeader />
                <div className="carousel-header-description">
                    <h1>{i18n.t('collection:header-collection-title')}</h1>
                    <p>{i18n.t('collection:header-collection-subtitle')}</p>
                    <button onClick={handleProposeMyHome} className="btn btn-got-invitation">
                        {i18n.t('collection:header-collection-button')}
                    </button>
                </div>
            </div>
            <PressSection />
            <div id="carousel-collection" className="carousel-container">
                <h2 className="carousel-title">{i18n.t('collection:title-carousel-collection')}</h2>
                <div className="border-top" />
                <div className="carousel-collection">
                    <CarouselCollection clickHandler={handleModal} />
                </div>
                <button onClick={handleModal} className="btn btn-primary btn-collection-black">
                    {i18n.t('collection:btn-view-more')}
                </button>
            </div>
            <div className="concept-container-background">
                <div className="concept-container">
                    <h2 className="concept-title">{i18n.t('collection:concept-title')}</h2>
                    <div className="border-top" />
                    <div className="concept-block-container">
                        <div className="concept-block-one">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/concept-collection/concept_collection_1.jpg`}
                            />
                            <div className="concept-description left">
                                <div className="border-top" />
                                <p className="concept-block-title">
                                    {i18n.t('collection:concept-title-block-one')}
                                </p>
                                <p
                                    className="concept-block-text"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t('collection:concept-description-block-one')
                                    }}
                                />
                                {Screen.noDesktop() && (
                                    <button className="btn-readMore" onClick={(e) => readMore(e, 1)}>
                                        {i18n.t('common:read-more')}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="concept-block-two">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/concept-collection/concept_collection_2.jpg`}
                            />
                            <div className="concept-description right">
                                <div className="border-top" />
                                <p className="concept-block-title">
                                    {i18n.t('collection:concept-title-block-two')}
                                </p>
                                <p
                                    className="concept-block-text"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t('collection:concept-description-block-two')
                                    }}
                                />
                                {Screen.noDesktop() && (
                                    <button className="btn-readMore" onClick={(e) => readMore(e, 2)}>
                                        {i18n.t('common:read-more')}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <a
                        className="concept-link"
                        href={i18n.t('collection:concept-link')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {i18n.t('common:learn_more')}
                    </a>
                </div>
            </div>
            <div className="benefit-container-background">
                <div className="benefit-container">
                    <h2 className="benefit-container-title">
                        {i18n.t('collection:title-benefit-collection')}
                    </h2>
                    <div className="border-top" />
                    <div className="benefit-items-container">
                        <div className="benefit-item">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/benefit/sec_and_discretion.svg`}
                                alt="sec_and_discretion"
                            />
                            <p className="benefit-item-title">{i18n.t('collection:exclusive-private')}</p>
                            <p>{i18n.t('collection:exclusive-private-text')}</p>
                        </div>
                        <div className="benefit-item">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/benefit/certified_luxury.svg`}
                                alt="certified_luxury"
                            />
                            <p className="benefit-item-title">{i18n.t('collection:certified-home')}</p>
                            <p>{i18n.t('collection:certified-home-text')}</p>
                        </div>
                        <div className="benefit-item">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/benefit/tailore_made_support.svg`}
                                alt="tailore_made_support"
                            />
                            <p className="benefit-item-title">{i18n.t('collection:assistance-service')}</p>
                            <p>{i18n.t('collection:assistance-service-text')}</p>
                        </div>
                        <div className="benefit-item">
                            <img
                                src={`${Utils.getCloudfrontDomain()}/images/website/collection/benefit/protection_and_guarantees.svg`}
                                alt="protection_and_guarantees"
                            />
                            <p className="benefit-item-title">{i18n.t('collection:protection-guarantees')}</p>
                            <p>{i18n.t('collection:protection-guarantees-text')}</p>
                        </div>
                    </div>
                    <div className="benefit-list-container">
                        <p className="benefit-list-title">{i18n.t('collection:benefit-list-title')}</p>
                        <p
                            className="benefit-list-description"
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('collection:benefit-list-description')
                            }}
                        />
                    </div>
                    <button onClick={handleProposeMyHome} className="btn btn-primary btn-collection-black">
                        {i18n.t('collection:become-member')}
                    </button>
                    <a
                        className="concept-link"
                        href={i18n.t('collection:benefit-link')}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {i18n.t('common:learn_more')}
                    </a>
                </div>
            </div>
            <div className="portrait-container-background">
                <div className="portrait-container">
                    <h2 className="portrait-container-title">
                        {i18n.t('collection:landing.portrait-container.title')}
                    </h2>
                    <div className="border-top"></div>
                    <div className="portrait-container-intro">
                        {i18n.t('collection:landing.portrait-container.intro')}
                    </div>
                    <div className="portrait-block-container">
                        {props.localeDescription === 'en' && (
                            <picture>
                                <source
                                    media="(max-width: 992px)"
                                    srcSet={`${Utils.getCloudfrontDomain()}/images/website/collection/collection_kristi_members_portrait_mobile.png`}
                                />
                                <img
                                    alt="member-portrait"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/collection/collection_kristi_members_portrait.png`}
                                />
                            </picture>
                        )}
                        {props.localeDescription !== 'en' && (
                            <picture>
                                <source
                                    media="(max-width: 992px)"
                                    srcSet={`${Utils.getCloudfrontDomain()}/images/website/collection/collection_members_portrait_mobile.png`}
                                />
                                <img
                                    alt="member-portrait"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/collection/collection_members_portrait.png`}
                                />
                            </picture>
                        )}

                        <div className="portrait-description-left">
                            <h3 className="portrait-title">
                                {i18n.t(
                                    `collection:${
                                        props.localeDescription === 'en'
                                            ? 'landing.portrait-title-us'
                                            : 'landing.portrait-title'
                                    }`
                                )}
                            </h3>
                            <div className="portrait-description">
                                {i18n.t(
                                    `collection:${
                                        props.localeDescription === 'en'
                                            ? 'landing.portrait-description-us'
                                            : 'landing.portrait-description'
                                    }`
                                )}
                            </div>
                            <button className="btn btn-primary btn-collection-black">
                                <a
                                    href={i18n.t('collection:landing.portrait-link')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {i18n.t('collection:landing.portrait-cta')}
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="questions-container-background">
                <div className="questions-container">
                    <h2 className="questions-title">{i18n.t('collection:questions')}</h2>
                    <div className="border-top" />
                    {_.times(5, (i) => (
                        <Accordion
                            key={i + 1}
                            id={`question${i + 1}`}
                            title={`collection:collection_question${i + 1}`}
                            description={`collection:collection_answer${i + 1}`}
                            symbol={currencySymbol}
                            price={productPrice}
                        />
                    ))}
                    <p
                        className="faq-text"
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('collection:more-questions-collection', {
                                faqLink: i18n.t('collection:faq-link')
                            })
                        }}
                    />
                </div>
            </div>
            <div className="contact-us-background">
                <div id="contact-us" className="contact-us-container">
                    <h2 className="contact-title">{i18n.t('collection:need-info')}</h2>
                    <div className="border-top" />
                    <p
                        className="contact-us-subtitle"
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('collection:contact-us-subtitle')
                        }}
                    />
                    <div className="collection-popup-buttons">
                        <button className="btn btn-primary btn-contact-collection-popup">
                            <i className="icon-phone_new icon" />
                            <a
                                href="https://homeexchangecollection.setmore.com/bookappointment"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {i18n.t('collection:book-a-call')}
                            </a>
                        </button>
                        <button className="btn btn-primary btn-contact-collection-popup">
                            <i className="icon-header-message-new icon" />
                            <a
                                href={`mailto:joincollection@homeexchange.com?subject=${i18n.t(
                                    'collection:object-mail-contact-collection'
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {i18n.t('collection:send-mail')}
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

LandingPage.propTypes = {
    accessToken: PropTypes.bool,
    localeDescription: PropTypes.string
};

const mapStateToProps = (state) => ({
    accessToken: Boolean(state.auth.accessToken),
    localeDescription: state.localeDescription
});

const mapDispatchToProps = (dispatch) => ({
    redirectToSearch: bindActionCreators(redirectToSearch, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
